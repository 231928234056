<template>
  <KTCard data-cy="profile-aside-menu">
    <div class="d-flex flex-column align-items-center justify-content-center">
      <div class="symbol symbol-100">
        <img class="symbol-label" :src="userAvatar" alt="avatar" />
      </div>

      <div class="w-100 my-4">
        <div class="user-info-item font-weight-bolder font-size-h5 text-dark-75">
          {{ userFullName }}
        </div>

        <div class="user-info-item navi-text text-muted text-hover-primary">
          {{ userEmail }}
        </div>

        <div class="user-info-item text-muted mt-2">
          {{ role }}
        </div>
      </div>
    </div>

    <div class="navi navi-bold navi-hover navi-active navi-link-rounded">
      <div class="navi-item mb-2">
        <b-link
          :to="profileEditRoute"
          :class="{ active: isEditProfileActive }"
          class="navi-link profile-link py-4"
        >
          <span class="navi-text font-size-lg text-center">
            {{ $t("component.quickUser.myProfile") }}
          </span>
        </b-link>
      </div>

      <!-- TODO will be added in v.2-->
      <div v-if="false" class="navi-item mb-2">
        <b-link
          :to="notificationRoute"
          :class="{ active: isNotificationActive }"
          class="navi-link profile-link py-4"
        >
          <span class="navi-text font-size-lg text-center">
            {{ $t("component.quickUser.notifications") }}
          </span>
        </b-link>
      </div>

      <div class="navi-item mb-2">
        <b-link
          :to="requisitesRoute"
          :class="{ active: isRequisitesActive }"
          class="navi-link profile-link py-4"
        >
          <span class="navi-text font-size-lg text-center">
            {{ $t("component.quickUser.requisites") }}
          </span>
        </b-link>
      </div>

      <div class="navi-item mb-2">
        <b-link
          :to="changePasswordRoute"
          :class="{ active: isChangePasswordActive }"
          class="navi-link profile-link py-4"
        >
          <span class="navi-text font-size-lg text-center">
            {{ $t("component.quickUser.changePassword") }}
          </span>
        </b-link>
      </div>
    </div>
  </KTCard>
</template>

<script>
import { mapGetters } from "vuex";
import KTCard from "@/components/card/KTCard";

export default {
  name: "ProfileAsideMenu",
  components: {
    KTCard,
  },

  computed: {
    ...mapGetters(["userFullName", "userEmail", "userRole"]),
    ...mapGetters("role", ["roleLabel"]),

    role() {
      return this.userRole ? this.roleLabel(this.userRole) : "";
    },

    userAvatar() {
      return `${process.env.BASE_URL}media/users/default.jpg`;
    },

    mailNotificationSvgPath() {
      return `${process.env.BASE_URL}media/svg/icons/Communication/Mail-notification.svg`;
    },

    profileEditRoute() {
      return { name: "ProfileEdit" };
    },

    notificationRoute() {
      return { name: "Notifications" };
    },

    requisitesRoute() {
      return { name: "Requisites" };
    },

    changePasswordRoute() {
      return { name: "ChangePassword" };
    },

    isEditProfileActive() {
      return this.$route.name === "ProfileEdit";
    },

    isNotificationActive() {
      return this.$route.name === "Notifications";
    },

    isRequisitesActive() {
      return this.$route.name === "Requisites";
    },

    isChangePasswordActive() {
      return this.$route.name === "ChangePassword";
    },
  },
};
</script>

<style lang="scss" scoped>
.user-info-item {
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
}
</style>
